import EmptyIcon from './EmptyIcon';
import loadableIcons from './LoadableIcons';

export type IconName = keyof typeof loadableIcons;
export type KexIconLoaderReturnType = ReturnType<typeof KexIconLoader>;

export const KexIconLoader = <N extends keyof typeof loadableIcons>(
  iconName?: N
) => {
  if (iconName === undefined) return EmptyIcon;

  return loadableIcons[iconName];
};

export default KexIconLoader;
